import { writable } from 'svelte/store';
import { browser } from '$app/environment';
import { UserRole, type User } from '../../../common/types';

export const user = writable<User>({
	id: 0,
	name: 'Anonymous',
	timestamp: 0,
	email: '',
	role: UserRole.Anonymous
});

const tokenInit = browser ? localStorage.getItem('token') : '';
export const token = writable<string>(tokenInit || '');

token.subscribe((value) => browser && localStorage.setItem('token', value));
